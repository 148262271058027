<template>
  <div class="space-y-10">
    <asom-card>
      <asom-grid :sm="1" :md="2">
        <asom-form-field label="Date">
          <asom-input-date-range v-model="filter.dateRange" />
        </asom-form-field>
        <asom-form-field label="Line" required :state="inputStates('filter.line')" error="Select a line">
          <asom-input-select disabled v-model="filter.line" :options="lineOptions" :state="inputStates('filter.line')" />
        </asom-form-field>
        <asom-form-field label="Station" required :state="inputStates('filter.station')" error="Select a station">
          <asom-multi-select v-model="filter.station" :options="stationOptions" :state="inputStates('filter.station')" />
        </asom-form-field>
        <asom-form-field label="Status">
          <asom-multi-select :objectModelValue="false" v-model="filter.status" :options="orderStatusOptions" />
        </asom-form-field>
      </asom-grid>
      <template #footer>
        <asom-export-to-excel :fileName="`Collection Summary Report`" :fields="tableData.fields"
          :labels="tableData.labels" :data="tableData.data" />
        <asom-button text="Reset Filters" variant="secondary" @click="resetFilters" />
        <asom-button text="Apply" :disabled="isLoading" :is-loading="isLoading" @click="loadData" />
      </template>
    </asom-card>
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-client-table v-else :data="tableData.data" :columns="tableData.fields" :labels="tableData.labels">
      <template v-slot:header_id>Cash Box/Bag Id</template>
    </asom-client-table>
  </div>
</template>
<script>
import inputStates from "@/mixins/inputStates";
import { displayUtcDateTime } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import moment from 'moment';
import { CompareResult } from "../../../../constants/APIEnums/inventoryEnums";
import { getSccfCashIn } from '../../../../services/cashManagement.service';

export default {
  data() {
    return {
      error: null,
      isLoading: false,
      filter: this.createInitFilters(),
      list: []
    }
  },
  mixins: [inputStates],
  mounted() {
    this.loadData()
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](this.filter.line.value);
    },
    orderStatusOptions() {
      return [
        {
          label: CompareResult.NIL.NAME,
          value: CompareResult.NIL.VALUE,
        },
        {
          label: CompareResult.ALIGN.NAME,
          value: CompareResult.ALIGN.VALUE,
        },
        {
          label: CompareResult.DISCREPANT.NAME,
          value: CompareResult.DISCREPANT.VALUE,
        }
      ];
    },
    tableData() {
      return {
        fields: ['collectionDate', 'staitonName', 'sccfOutGoingAmount', 'verifiedAmount', 'status'],
        labels: ['Date', 'Station', 'Outgoing SCCF', 'Certis MC CASHIN', 'Status'],
        data: this.list.map(item => ({
          ...item,
          transactionDate: displayUtcDateTime(item.transactionDate, 'DD/MM/YYYY'),
          collectionDate: displayUtcDateTime(item.collectionDate, 'DD/MM/YYYY'),
          status: !item.status ? CompareResult.NIL.NAME : item.status === 1 ? CompareResult.ALIGN.NAME : CompareResult.DISCREPANT.NAME,
          sccfOutGoingAmount: item.sccfOutGoingAmount ?? 'Nil',
          verifiedAmount: item.verifiedAmount ?? 'Nil'
        }))
      }
    }
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      this.list.length = 0;
      const res = await getSccfCashIn({
        "lineId": this.filter.line.value,
        "stationIds": this.filter.station.map(item => item.value),
        "startDate": displayUtcDateTime(this.filter.dateRange.start, 'MM/DD/YYYY 00:00:00'),
        "endDate":  displayUtcDateTime(this.filter.dateRange.end, 'MM/DD/YYYY 23:59:59'),
        "status": this.filter.status,
        "skip": 0,
        "take": 100,
        "search": ""
      })

      if (res.success) {
        this.list = res.payload.list;
      } else {
        this.list.length = 0;
      }

      this.isLoading = false;
    },
    createInitFilters() {
      return {
        station: [],
        line: this.$store.getters["auth/userLineOption"],
        dateRange: {
          start: moment().toDate(),
          end: moment().add(1, 'days').toDate()
        },
        status: []
      }
    },
    resetFilters() {
      this.filter = this.createInitFilters();
    }
  }
}
</script>